<template>
  <v-layout>
    <v-flex
      v-for="(row, i) in config"
      :key="i"
      class="mr-5"
      sm3
    >
      <vbt-date-picker
        v-if="row.type === 'daterange'"
        v-model="form.dates"
        :clearable="false"
        :label="row.label"
        confirm
        range
      />

      <v-blacker-combobox
        v-if="row.type === 'printCustomerId'"
        v-model="form[row.name]"
        :items="clientsOptions"
        :label="row.label"
      />

      <v-blacker-combobox
        v-if="row.type === 'printProviderId'"
        v-model="form[row.name]"
        :items="providersOptions"
        :label="row.label"
      />

      <v-blacker-combobox
        v-if="row.type === 'accountId'"
        v-model="form[row.name]"
        :items="accountsOptions"
        :label="row.label"
      />

      <v-text-field
        v-if="row.type === 'int'"
        v-model.trim.number="form[row.name]"
        type="number"
        :label="row.label"
      />

      <v-text-field
        v-if="row.type === 'string' || row.type === 'timeSpan'"
        v-model.trim="form[row.name]"
        :label="row.label"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ReportForm',

  props: {
    form: {
      type: Object,
      required: true,
    },

    config: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters('accounts', ['accountsOptions', 'clientsOptions', 'providersOptions']),
  },

  created() {
    this.getAccounts({ activeOnly: false });
  },

  methods: {
    ...mapActions('accounts', ['getAccounts']),
  },
};
</script>
