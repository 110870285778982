import { wrapToLoadingFn } from '@helpers';

import { ReportsLocalStorageService } from '../../_services';

import {
  ReportSelector, InProgressReports, ReportForm, ReportTypeDescription,
} from './_components';

export default {
  name: 'IrReportGenerator',

  props: {
    types: {
      type: Array,
      required: true,
    },

    config: {
      type: Object,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    ReportSelector,
    InProgressReports,
    ReportForm,
    ReportTypeDescription,
  },

  data() {
    return {
      generateLoading: false,
      reportType: '',
      forms: {},
    };
  },

  watch: {
    types: {
      handler(newValue) {
        if (newValue.length) {
          [this.reportType] = newValue;
        }
      },
      deep: true,
    },

    config: {
      handler(newValue) {
        Object.keys(newValue)
          .map(key => this.$set(this.forms, key, newValue[key].createForm()));
      },
      deep: true,
    },
  },

  methods: {
    generateReport() {
      this.wrapToLoadingFn({
        req: this.config[this.reportType].action.bind(
          {},
          this.config[this.reportType].parseForm(this.forms[this.reportType]),
        ),
        loadingFlagName: 'generateLoading',
        onSuccess: ({ reportId }) => {
          this.$VBlackerTheme.notification.success('Report added to queue');
          ReportsLocalStorageService.addReportIdToInProgress(reportId);
          this.$refs.inProgressReports.refreshInProgressQueue();
          this.resetForm();
        },
      });
    },

    resetForm() {
      this.$set(this.forms, this.reportType, this.config[this.reportType].createForm());
    },

    wrapToLoadingFn,
  },
};
