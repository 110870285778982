<template>
  <vbt-content-box
    :loading="loading"
    :loading-error="loadingError"
    :no-content="noContent"
    :go-back-btn="goBack"
    title="Reports History"
  >
    <vbt-table
      :headers="headers"
      :items="items"
    >
      <template #timestamp="{ item: { timestamp } }">
        {{ formatDisplayDate(timestamp) }}
      </template>

      <template #arguments="{ item: { arguments: code } }">
        <v-btn
          :disabled="!code"
          color="info"
          small
          icon
          @click="showArgumentsCode(code)"
        >
          <v-icon>
            remove_red_eye
          </v-icon>
        </v-btn>
      </template>

      <template #url="{ item: { url } }">
        <a
          :href="url"
          target="_blank"
          class="info--text"
        >
          Show result
        </a>
      </template>
    </vbt-table>

    <vbt-pagination
      v-model="pageNum"
      :items-count="pagedItemsCount"
    />

    <vbt-dialog
      v-model="argumentsCodeDialog"
      title="Arguments"
      hide-submit-btn
    >
      <vbt-code
        :value="argumentsCode"
        readonly
      />
    </vbt-dialog>
  </vbt-content-box>
</template>

<script>
import { usePagination } from '@mixins/factories';
import { formatDisplayDate } from '@helpers';

const paginationOptions = { itemsName: 'items' };
const headers = Object.freeze([
  { text: 'Login Name', value: 'loginName' },
  { text: 'Type', value: 'type' },
  { text: 'Timestamp', value: 'timestamp' },
  { text: 'Arguments', value: 'arguments', width: '200px' },
  { text: '', value: 'url', width: '110px' },
]);

export default {
  name: 'IrReportsHistory',

  props: {
    items: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },

    loadingError: {
      type: Object,
      required: true,
    },
  },

  mixins: [
    usePagination(paginationOptions),
  ],

  data() {
    return {
      argumentsCodeDialog: false,
      argumentsCode: '',

      headers,
    };
  },

  methods: {
    showArgumentsCode(code) {
      this.argumentsCode = JSON.stringify(JSON.parse(code), null, '\t');
      this.argumentsCodeDialog = true;
    },

    formatDisplayDate,
  },
};
</script>
