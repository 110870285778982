<template>
  <v-menu
    nudge-bottom="25"
    open-on-hover
    bottom
    left
  >
    <template #activator="{ on }">
      <v-chip
        small
        v-on="on"
      >
        Report Type:

        <strong
          style="font-size: 13px; margin-right: 5px;"
          class="ml-1 success--text"
        >
          {{ reportType }}
        </strong>

        <v-icon small>
          expand_more
        </v-icon>
      </v-chip>
    </template>

    <v-list>
      <v-list-item
        v-for="type in items"
        :key="type"
        v-show="type !== reportType"
        @click="reportType = type"
      >
        <v-list-item-title>
          {{ type }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'ReportSelector',

  model: {
    prop: 'value',
    type: 'input',
  },

  props: {
    items: {
      type: Array,
      required: true,
    },

    value: {
      type: String,
      required: true,
    },
  },

  computed: {
    reportType: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
};
</script>
