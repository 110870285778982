<template>
  <vbt-search
    v-model="searchData"
    :items="searchFieldsConfig"
    title="Search Reports History"
    @reset="onResetSearch"
    @search="onSearch"
  />
</template>

<script>
import { useDataLoading, useSearch } from '@mixins/factories';

const dataLoadingOptions = { getterName: 'handleAfterSearch' };

const searchOptions = {
  initialSearchDataStates: ['type', 'dates'],
  customTypes: {
    dates: 'array',
  },
};

export default {
  name: 'IrReportsSearch',

  props: {
    types: {
      type: Array,
      required: true,
    },
  },

  mixins: [
    useDataLoading(dataLoadingOptions),
    useSearch(searchOptions),
  ],

  computed: {
    searchFieldsConfig() {
      return [
        {
          value: 'type',
          label: 'Type',
          type: 'combobox',
          items: this.types.map(t => ({ value: t, text: t })),
        },
        {
          value: 'dates',
          label: 'Date Range',
          type: 'daterange',
          flex: 'sm4',
        },
      ];
    },
  },

  methods: {
    handleAfterSearch(params) {
      this.$emit('search', params);
    },
  },
};
</script>
